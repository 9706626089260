














  import { PRODUCTS } from '@/constant/Enums';
  import Vue from 'vue';
  import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

  @Component({
    name: "Product",
    components: {},
    mixins:[]
  })
  export default class Product extends Vue {

    public get availableProduct() {
      return PRODUCTS;
    }

    showNav = false
    @Watch('$route', { immediate: true, deep: true }) onChangeRoute(){
      this.showNav = this.$route.name === '/product/'
    }

  }
